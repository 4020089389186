import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash/fp'

// Components
import Layout from 'components/layout'
import { Blocks } from 'components/Blocks'
import SEO from 'components/seo'

// Types
import { ContentfulPage } from 'types/contentful'

type Props = {
  data: {
    page: ContentfulPage
  }
  pageContext: {
    slug: string
  }
}

const PageTemplate: React.FC<Props> = ({ data }) => {
  const { page } = data

  if (!page) {
    return null
  }

  const theme = page.theme ? page.theme : 'Dark'
  const pageTitle = page.seoTitle ? page.seoTitle : page.title
  const meta =
    page.blockIndexing === true
      ? [
          {
            name: 'robots',
            content: 'noindex',
          },
        ]
      : []
  return (
    <Layout theme={theme}>
      <SEO
        title={pageTitle || ''}
        description={get('excerpt.excerpt', page)}
        meta={meta}
      />
      <Blocks blocks={page.blocks} />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query regularPage($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      title
      seoTitle
      blockIndexing
      theme
      excerpt {
        excerpt
      }
      blocks {
        ... on ContentfulBlock {
          ...BlocksFragment
        }
        ... on ContentfulBlockLatestBlogPosts {
          __typename
          id
          title
          category
        }
        ... on ContentfulHtmlCode {
          __typename
          id
          name
          code {
            code
          }
        }
      }
    }
  }
`
